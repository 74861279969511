.root {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
}
.freeBox___9Jh2S {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 1;
  min-width: 0;
}
.img__haZQy {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 426px;
}
.img__haZQy > picture > img {
  object-fit: cover;
}
.freeBox__sdave {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 1;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.01s;
  min-width: 0;
  -webkit-transition-property: opacity;
  -webkit-transition-duration: 0.01s;
}
.root:hover .freeBox__sdave {
  opacity: 1;
}
.htmlVideo__hcLfp:global(.__wab_instance) {
  width: 426px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
