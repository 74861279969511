.featureDetailHome {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .featureDetailHome {
    position: relative;
  }
}
._005FeatureDetail {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-width: var(--token-c-29j4nMe);
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: var(--token-eX3zVaWtY) var(--token-TCakqYrfy) var(--token-omGhEM8OI);
}
._005FeatureDetail > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - var(--token-TCakqYrfy));
  width: calc(100% + var(--token-TCakqYrfy));
  --plsmc-rc-col-gap: var(--token-TCakqYrfy);
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
._005FeatureDetail > :global(.__wab_flex-container) > *,
._005FeatureDetail > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
._005FeatureDetail > :global(.__wab_flex-container) > picture > img,
._005FeatureDetail
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-TCakqYrfy);
  margin-top: var(--token-TCakqYrfy);
}
._005FeatureDetail > :global(.__wab_flex-container) > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 600px) {
  ._005FeatureDetail {
    width: 100%;
    height: auto;
    flex-direction: row;
    display: flex;
    flex-shrink: 1;
    min-width: 0;
  }
}
@media (min-width: 600px) {
  ._005FeatureDetail > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    min-width: 0;
  }
}
@media (min-width: 600px) {
  ._005FeatureDetail > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
@media (min-width: 900px) {
  ._005FeatureDetail {
    flex-direction: row;
    padding: 70px var(--token-omGhEM8OI);
  }
}
@media (min-width: 900px) {
  ._005FeatureDetail > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  ._005FeatureDetail > :global(.__wab_flex-container) > *,
  ._005FeatureDetail
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  ._005FeatureDetail > :global(.__wab_flex-container) > picture > img,
  ._005FeatureDetail
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
@media (min-width: 900px) {
  ._005FeatureDetail > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 900px) {
  ._005FeatureDetail > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 1024px) {
  ._005FeatureDetail {
    flex-direction: row;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1024px) {
  ._005FeatureDetail > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: calc(0px - var(--token-cTiaoKBgu));
    width: calc(100% + var(--token-cTiaoKBgu));
    --plsmc-rc-col-gap: var(--token-cTiaoKBgu);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1024px) {
  ._005FeatureDetail > :global(.__wab_flex-container) > *,
  ._005FeatureDetail
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  ._005FeatureDetail > :global(.__wab_flex-container) > picture > img,
  ._005FeatureDetail
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-cTiaoKBgu);
    margin-top: 0px;
  }
}
@media (min-width: 1024px) {
  ._005FeatureDetail > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 1024px) {
  ._005FeatureDetail > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 1280px) {
  ._005FeatureDetail {
    padding-left: 0px;
    padding-right: 0px;
    width: 80%;
  }
}
@media (min-width: 1536px) {
  ._005FeatureDetail {
    width: 65%;
  }
}
@media (min-width: 1900px) {
  ._005FeatureDetail {
    width: 50%;
  }
}
._005FeatureDetaildown > :global(.__wab_flex-container) {
  flex-wrap: wrap-reverse;
}
@media (min-width: 900px) {
  ._005FeatureDetailright {
    flex-direction: row-reverse;
  }
}
@media (min-width: 900px) {
  ._005FeatureDetailright > :global(.__wab_flex-container) {
    flex-direction: row-reverse;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  ._005FeatureDetailright > :global(.__wab_flex-container) > *,
  ._005FeatureDetailright
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  ._005FeatureDetailright > :global(.__wab_flex-container) > picture > img,
  ._005FeatureDetailright
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
@media (min-width: 1024px) {
  ._005FeatureDetailright {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1024px) {
  ._005FeatureDetailright > :global(.__wab_flex-container) {
    flex-direction: row-reverse;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1024px) {
  ._005FeatureDetailright > :global(.__wab_flex-container) > *,
  ._005FeatureDetailright
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  ._005FeatureDetailright > :global(.__wab_flex-container) > picture > img,
  ._005FeatureDetailright
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
.img {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
@media (min-width: 900px) {
  .img {
    justify-content: center;
    align-items: center;
  }
}
.imgdown {
  align-self: flex-end;
}
.img__eCjkR {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: auto;
}
.img__eCjkR > picture > img {
  object-fit: cover;
}
.htmlVideo__yWa6U:global(.__wab_instance) {
  width: 640px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.content {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.content > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.content > :global(.__wab_flex-container) > *,
.content > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content > :global(.__wab_flex-container) > picture > img,
.content
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-TCakqYrfy);
}
@media (min-width: 600px) {
  .content {
    padding: 0px;
  }
}
@media (min-width: 900px) {
  .content > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: flex-start;
  }
}
.title2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 328px;
}
@media (min-width: 600px) {
  .title2 {
    width: 100%;
    min-width: 0;
  }
}
.slotTargetTitle {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #303030;
  text-align: center;
  line-height: 1.4;
}
@media (min-width: 900px) {
  .slotTargetTitle {
    font-size: 38px;
    text-align: left;
  }
}
.paragraph2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 328px;
}
@media (min-width: 600px) {
  .paragraph2 {
    width: 100%;
    min-width: 0;
  }
}
.slotTargetParagraph {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #303030;
  text-align: center;
  line-height: 1.5;
}
@media (min-width: 900px) {
  .slotTargetParagraph {
    font-size: 20px;
    text-align: left;
  }
}
.btn {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: calc(16px + var(--token-TCakqYrfy)) !important;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 900px) {
  .btn {
    align-items: center;
    justify-content: flex-start;
    align-self: flex-start;
    margin-top: calc(16px + var(--token-TCakqYrfy)) !important;
  }
}
.link__eGxPx {
  position: relative;
  background: #d24343;
  border: 1px solid #ba0e0e;
}
