.root {
  background: #860ee6;
  color: #ffffff;
  position: relative;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  line-height: 1.5;
  border-radius: 6px;
  padding: 8px 16px;
  border: 1px solid #860ee6;
}
@media (min-width: 600px) {
  .root {
    background: #860ee6;
    color: #ffffff;
    flex-shrink: 0;
    flex-grow: 0;
    border-color: #860ee6;
  }
}
@media (min-width: 900px) {
  .root {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}
@media (min-width: 1024px) {
  .root {
    padding: 8px 16px;
  }
}
.rootglobal_ctaLevel_monthly {
  color: #ffffff;
  background: #860ee6;
  border-color: #860ee6;
}
.rootglobal_ctaLevel_annual {
  color: #ffffff;
  background: #860ee6;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 8px 16px;
  border: 1px solid #860ee6;
}
@media (min-width: 900px) {
  .rootglobal_ctaLevel_monthly {
    padding: 8px 16px;
  }
}
.root:hover {
  background: #500c86;
  color: #ffffff;
  border-color: #790ecf;
}
.rootglobal_ctaLevel_annual:hover {
  color: #ffffff;
}
