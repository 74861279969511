.root {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  flex-shrink: 0;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .root {
    overflow: hidden;
  }
}
@media (min-width: 900px) {
  .root {
    height: auto;
  }
}
.headerHpBookday:global(.__wab_instance) {
  max-width: 100%;
}
