.container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marketingLists {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: var(--token-omGhEM8OI) var(--token-TCakqYrfy);
}
.marketingLists > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - var(--token-u8uQ-APga));
  width: calc(100% + var(--token-u8uQ-APga));
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.marketingLists > :global(.__wab_flex-container) > *,
.marketingLists > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.marketingLists > :global(.__wab_flex-container) > picture > img,
.marketingLists
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-u8uQ-APga);
  margin-top: var(--token-TCakqYrfy);
}
@media (min-width: 1024px) {
  .marketingLists > :global(.__wab_flex-container) {
    flex-wrap: nowrap;
    align-content: stretch;
    align-items: flex-start;
  }
}
@media (min-width: 1280px) {
  .marketingLists > :global(.__wab_flex-container) {
    margin-left: calc(0px - var(--token-TCakqYrfy));
    width: calc(100% + var(--token-TCakqYrfy));
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1280px) {
  .marketingLists > :global(.__wab_flex-container) > *,
  .marketingLists > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .marketingLists > :global(.__wab_flex-container) > picture > img,
  .marketingLists
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-TCakqYrfy);
    margin-top: 0px;
  }
}
@media (min-width: 1900px) {
  .marketingLists {
    margin: 0px auto auto;
  }
}
.link___4H7F3 {
  display: flex;
  width: 47%;
}
@media (min-width: 600px) {
  .link___4H7F3 {
    width: 48%;
  }
}
@media (min-width: 900px) {
  .link___4H7F3 {
    width: 32%;
  }
}
@media (min-width: 1024px) {
  .link___4H7F3 {
    width: 100%;
    min-width: 0;
  }
}
.clickEventReporter__ohRL:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0px 3px 10px 0px #0000001a;
  position: relative;
  min-width: 0;
  border-radius: 12px;
}
.img__cvGk0 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 426px;
}
.img__cvGk0 > picture > img {
  object-fit: cover;
}
.htmlVideo__xrgNa:global(.__wab_instance) {
  width: 426px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox__ghA6H {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-height: 66px;
  min-width: 0;
  padding: var(--token-u8uQ-APga) 12px var(--token-TCakqYrfy);
}
.text__q9Brj {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-PsTTGOOvU);
  font-weight: 700;
  font-size: var(--token-jFlfsKM8_wXg);
  line-height: var(--token-MHxx4_Rh4);
  padding-left: 0px;
}
.link__n8TcA {
  display: flex;
  width: 47%;
}
@media (min-width: 600px) {
  .link__n8TcA {
    width: 48%;
  }
}
@media (min-width: 900px) {
  .link__n8TcA {
    width: 32%;
  }
}
@media (min-width: 1024px) {
  .link__n8TcA {
    width: 100%;
    min-width: 0;
  }
}
.clickEventReporter__zdjbG:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.card2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0px 3px 10px 0px #0000001a;
  position: relative;
  min-width: 0;
  border-radius: 12px;
}
.img__bbbOf {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 426px;
}
.img__bbbOf > picture > img {
  object-fit: cover;
}
.htmlVideo__ub4FI:global(.__wab_instance) {
  width: 426px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox__c7Oo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-height: 66px;
  min-width: 0;
  padding: var(--token-u8uQ-APga) 12px var(--token-TCakqYrfy);
}
.text___3JDsf {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-PsTTGOOvU);
  font-weight: 700;
  font-size: var(--token-jFlfsKM8_wXg);
  line-height: var(--token-MHxx4_Rh4);
}
.link__oCsjc {
  display: flex;
  width: 47%;
}
@media (min-width: 600px) {
  .link__oCsjc {
    width: 48%;
  }
}
@media (min-width: 900px) {
  .link__oCsjc {
    width: 32%;
  }
}
@media (min-width: 1024px) {
  .link__oCsjc {
    width: 100%;
    min-width: 0;
  }
}
.clickEventReporter__xj3E0:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.card4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0px 3px 10px 0px #0000001a;
  position: relative;
  min-width: 0;
  border-radius: 12px;
}
.img__aImGq {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 426px;
}
.img__aImGq > picture > img {
  object-fit: cover;
}
.htmlVideo__gYSj:global(.__wab_instance) {
  width: 426px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox__mK6YK {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  min-height: 66px;
  min-width: 0;
  padding: var(--token-u8uQ-APga) 12px var(--token-TCakqYrfy);
}
.span {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-PsTTGOOvU);
  font-weight: 700;
  font-size: var(--token-jFlfsKM8_wXg);
  line-height: var(--token-MHxx4_Rh4);
  white-space: pre-wrap;
  text-align: left;
  width: 100%;
  min-width: 0;
}
.link__qwAnd {
  display: flex;
  width: 47%;
}
@media (min-width: 600px) {
  .link__qwAnd {
    width: 48%;
  }
}
@media (min-width: 900px) {
  .link__qwAnd {
    width: 32%;
  }
}
@media (min-width: 1024px) {
  .link__qwAnd {
    width: 100%;
    min-width: 0;
  }
}
.clickEventReporter___1Pt8P:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.card3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0px 3px 10px 0px #0000001a;
  position: relative;
  min-width: 0;
  border-radius: 12px;
}
.img__rsIa5 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 426px;
}
.img__rsIa5 > picture > img {
  object-fit: cover;
}
.htmlVideo___2Fzka:global(.__wab_instance) {
  width: 426px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox___8FjNo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-height: 66px;
  min-width: 0;
  padding: var(--token-u8uQ-APga) 12px var(--token-TCakqYrfy);
}
.text__w8T7T {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-PsTTGOOvU);
  font-weight: 700;
  font-size: var(--token-jFlfsKM8_wXg);
  line-height: var(--token-MHxx4_Rh4);
}
.link__u48KW {
  display: flex;
  width: 47%;
}
@media (min-width: 600px) {
  .link__u48KW {
    width: 48%;
  }
}
@media (min-width: 900px) {
  .link__u48KW {
    width: 32%;
  }
}
@media (min-width: 1024px) {
  .link__u48KW {
    width: 100%;
    min-width: 0;
  }
}
.clickEventReporter__kpTki:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.card5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0px 3px 10px 0px #0000001a;
  position: relative;
  min-width: 0;
  border-radius: 12px;
}
.img__snDdC {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 426px;
}
.img__snDdC > picture > img {
  object-fit: cover;
}
.htmlVideo__h27Qr:global(.__wab_instance) {
  width: 426px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox__a1JiX {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-height: 66px;
  min-width: 0;
  padding: var(--token-u8uQ-APga) 12px var(--token-TCakqYrfy);
}
.text__cja5M {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-PsTTGOOvU);
  font-weight: 700;
  font-size: var(--token-jFlfsKM8_wXg);
  line-height: var(--token-MHxx4_Rh4);
  padding-right: 0px;
}
.link__xpw5Z {
  display: flex;
  width: 47%;
}
@media (min-width: 600px) {
  .link__xpw5Z {
    width: 48%;
  }
}
@media (min-width: 900px) {
  .link__xpw5Z {
    width: 32%;
  }
}
@media (min-width: 1024px) {
  .link__xpw5Z {
    width: 100%;
    min-width: 0;
  }
}
.clickEventReporter__hLaFs:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.card6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0px 3px 10px 0px #0000001a;
  position: relative;
  min-width: 0;
  border-radius: 12px;
}
.img__wws6F {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 426px;
}
.img__wws6F > picture > img {
  object-fit: cover;
}
.htmlVideo__ltuQ:global(.__wab_instance) {
  width: 426px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox___1O2Hv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-height: 66px;
  min-width: 0;
  padding: var(--token-u8uQ-APga) 12px var(--token-TCakqYrfy);
}
.text___56T1W {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-PsTTGOOvU);
  font-weight: 700;
  font-size: var(--token-jFlfsKM8_wXg);
  line-height: var(--token-MHxx4_Rh4);
}
