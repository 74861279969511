.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.heroSlider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.base:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.headerHomepageDefault:global(.__wab_instance) {
  max-width: 100%;
}
.campaignContent:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.holi2025:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.headerHpBookday:global(.__wab_instance) {
  max-width: 100%;
}
.blog:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.vdayHomepageHeader:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.blogCard:global(.__wab_instance) {
  max-width: 100%;
}
